import { PictureTagDTO } from '@library/dto/picture-tag.dto';
import { CustomFieldInspectionAnswerPairDTO } from '@library/dto/workflow/custom-fields.dto';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { qpIsFiniteNumber } from '@library/functions/checks/qp-is-finite-number';
import { EQpImageElementType } from '@library/models/qp-image.models';
import { EQpInspectionTypeLabel } from '@library/models/qp-inspection.models';
import { EQpQuestionType } from '@library/models/qp-question.models';
import {
  IQpTestsChecklistElementTable,
  IQpTestsChecklistElementTableRows,
  IQpTestsChecklistElementTableValue,
  QpTestsChecklistElementTableColumnType,
} from '@library/models/qp-tests-checklist-element.models';
import { IspWorkflow } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow/isp-workflow';
import { IspWorkflowStepActionCustomFields } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-custom-fields/isp-workflow-step-action-custom-fields';
import {
  clearInspection,
  loadInspection,
  loadInspectionErrorForbidden,
  loadInspectionSuccess,
  markInspectionAsAbortedSuccess,
  updateAqlDefectsSuccess,
  updateAqlReasonForChangesSuccess,
  updatedSuccessInspectionStatus,
  updateInspectionDate,
  updateSamplingSizesSuccess,
} from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-store.actions';
import { IInspectionState } from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-store.models';
import {
  addCustomMeasurementProductSampleSuccess,
  addInspectionAttachmentSuccess,
  addInspectionPictureSuccess,
  addSiteLocation,
  clearSiteLocation,
  commentTestSuccess,
  completeDefectsChecklistSuccess,
  completeReferenceSampleSuccess,
  completeSampleCollectionSuccess,
  completeStartingPicturesSuccess,
  confirmFindingsSummaryReviewSuccess,
  createDefectSuccess,
  createPictureTagSuccess,
  deleteInspectionAttachmentSuccess,
  deleteInspectionImageSuccess,
  deletePictureTagSuccess,
  deleteTestChecklistTableRowSuccess,
  IDeleteWorkflowImage,
  loadWorkflowSuccess,
  markAsNotApplicableSuccess,
  orderInspectionPicturesSuccess,
  saveCheckSuccess,
  saveMeasurementSuccess,
  saveProductPictureSuccess,
  saveRecipientsSuccess,
  saveSimplifiedMeasuresCommentSuccess,
  saveSimplifiedMeasuresMarkedAsNotApplicableSuccess,
  saveSimplifiedMeasuresSuccess,
  saveTestAnswerSuccess,
  saveTestTableRows,
  saveWorkflowActionCustomFieldsValueSuccess,
  updateAvailableCartons,
  updateAvailableCartonsSuccess,
  updateDefectCommentSuccess,
  updateDefectSuccess,
  updateGlobalRemarksSuccess,
  updateInspectionPictureDataSuccess,
  updateInspectionPicturesDataSuccess,
  updateIsInspectionMarkedAsPendingSuccess,
  updatePictureTagSuccess,
  updateProductActualQuantitiesSuccess,
  updateSimplifiedMeasurementSamplingModificationReasonSuccess,
  updateSimplifiedMeasurementSamplingSuccess,
  updateSimplifiedMeasuresResult,
} from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-workflow-store.actions';
import WorkflowUtils from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/workflow.utils';
import { IMeasure, IVariance } from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { IChecklistImageData } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { IAbstractMultiProduct } from '@one/app/shared/models/products/product.models';
import {
  ESamplingSizeTypes,
  IFixedSamplingSize,
  ISamplingSize,
  ISamplingSizeProduct,
  IWorkflowFixedSamplingSize,
  IWorkflowSamplingSize,
  SamplingSize,
} from '@one/app/shared/models/sampling/sampling-size.models';
import {
  EWorkflowActionType,
  IProductActualQuantities,
  IWorkflow,
  IWorkflowAction,
  IWorkflowStep,
} from '@one/app/shared/models/workflow/workflow.models';
import { EWorkflowTemplateStepActionInspectionPreparationCalculationMethod } from '@one/app/shared/models/workflows/workflows-templates.models';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { QimaOptionalType } from '@qima/ngx-qima';
import { produce } from 'immer';
import { cloneDeep, isArray, isEmpty, isNil } from 'lodash/index';

const INSPECTION_INITIAL_STATE: IInspectionState = {
  isLoading: false,
  isAborted: false,
  inspection: null,
  status: null,
  workflow: null,
};

/**
 * @description Reducer for the inspection store
 * @returns {ActionReducer<IInspectionState, Action>} The reducer
 */
function actionReducer(): ActionReducer<IInspectionState, Action> {
  return createReducer<IInspectionState>(
    INSPECTION_INITIAL_STATE,

    on(clearInspection, (): IInspectionState => {
      return {
        ...INSPECTION_INITIAL_STATE,
        isLoading: false,
      };
    }),

    on(loadInspection, (state): IInspectionState => {
      return {
        ...state,
        isLoading: true,
      };
    }),

    on(loadInspectionSuccess, (state, { inspection }): IInspectionState => {
      return {
        ...state,
        isLoading: false,
        inspection,
        status: inspection.status,
      };
    }),

    on(loadInspectionErrorForbidden, (state): IInspectionState => {
      return {
        ...state,
        isLoading: false,
      };
    }),

    on(updatedSuccessInspectionStatus, (state, { status }): IInspectionState => {
      return {
        ...state,
        isLoading: false,
        inspection: state.inspection
          ? {
              ...state.inspection,
              status,
            }
          : state.inspection,
        status,
      };
    }),

    on(
      updateProductActualQuantitiesSuccess,
      (state: IInspectionState, action: { productActualQuantities: IProductActualQuantities }): IInspectionState => {
        if (isNil(state.workflow)) {
          return state;
        }

        const updatedWorkflow: IWorkflow = WorkflowUtils.updateProductActualQuantities(state.workflow, action.productActualQuantities);

        return {
          ...state,
          workflow: updatedWorkflow,
        };
      }
    ),

    on(updateAqlDefectsSuccess, (state, { aqlDefects, purchaseOrderProductId }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      if (state.inspection?.isAqlPerProduct) {
        const perProductAqlAndSampling = WorkflowUtils.getUpdatedPerProductAqlAndSampling(
          state.inspection,
          state.workflow,
          purchaseOrderProductId,
          { aqlDefects }
        );

        return {
          ...state,
          isLoading: false,
          workflow: {
            ...state.workflow,
            perProductAqlAndSampling,
          },
        };
      }

      return {
        ...state,
        isLoading: false,
        workflow: {
          ...state.workflow,
          aqlDefects,
        },
      };
    }),

    on(markInspectionAsAbortedSuccess, (state, { isAborted }): IInspectionState => {
      return {
        ...state,
        isAborted,
      };
    }),

    on(updateSamplingSizesSuccess, (state, { samplingSize, purchaseOrderProductId }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      if (state.inspection?.isAqlPerProduct) {
        const perProductAqlAndSampling = WorkflowUtils.getUpdatedPerProductAqlAndSampling(
          state.inspection,
          state.workflow,
          purchaseOrderProductId,
          { sampling: samplingSize }
        );
        const totalLotSize: number = WorkflowUtils.getPerProductAqlAndSamplingTotalLotSize(perProductAqlAndSampling);
        const updatedWorkflow: IWorkflow = WorkflowUtils.updateTestsSamplingSize(state.workflow, totalLotSize);

        return {
          ...state,
          isLoading: false,
          workflow: {
            ...updatedWorkflow,
            perProductAqlAndSampling,
          },
        };
      }

      const updatedWorkflow: IWorkflow = WorkflowUtils.updateWorkflowSamplingSize(state.workflow, samplingSize);

      return {
        ...state,
        workflow: {
          ...updatedWorkflow,
          samplingSize: {
            aqlModificationReason: (state.workflow.samplingSize as IWorkflowSamplingSize)?.aqlModificationReason,
            ...samplingSize,
          },
        },
      };
    }),

    on(updateAqlReasonForChangesSuccess, (state, { reason, purchaseOrderProductId }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      if (state.inspection?.isAqlPerProduct) {
        const perProductAqlAndSampling = WorkflowUtils.getUpdatedPerProductAqlAndSampling(
          state.inspection,
          state.workflow,
          purchaseOrderProductId,
          { aqlModificationReason: reason }
        );

        return {
          ...state,
          isLoading: false,
          workflow: {
            ...state.workflow,
            perProductAqlAndSampling,
          },
        };
      }

      if (!isNil(state.workflow.samplingSize)) {
        return {
          ...state,
          workflow: {
            ...state.workflow,
            samplingSize: {
              ...state.workflow.samplingSize,
              aqlModificationReason: reason,
            },
          },
        };
      }

      return state;
    }),

    on(updateAvailableCartons, (state, { availableCartons, calculationMethod, cartonsToPick }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      return {
        ...state,
        workflow: state.workflow
          ? {
              ...state.workflow,
              totalCartons: availableCartons ?? undefined,
              cartonCalculationMethod: calculationMethod,
              pickedCartons: cartonsToPick ?? undefined,
            }
          : null,
      };
    }),

    on(updateAvailableCartonsSuccess, (state, { cartonsToPick }): IInspectionState => {
      return {
        ...state,
        workflow: state.workflow
          ? {
              ...state.workflow,
              pickedCartons: cartonsToPick,
            }
          : null,
      };
    }),

    on(loadWorkflowSuccess, (state, { workflow }): IInspectionState => {
      const ispWorkflow = new IspWorkflow(workflow);
      const preparationAction = ispWorkflow.getInspectionPreparationAction();
      let updatedWorkflow: IWorkflow = workflow;
      const inspectionSamplingSize: QimaOptionalType<SamplingSize> = state?.inspection?.samplingSize;
      const isSelfPickingPreparationActionOrderedQuantity =
        EQpInspectionTypeLabel.SP === state.inspection?.inspectionType.label &&
        preparationAction &&
        EWorkflowTemplateStepActionInspectionPreparationCalculationMethod.ORDERED_QUANTITY === preparationAction.calculationMethod;

      if (
        state.inspection &&
        (isSelfPickingPreparationActionOrderedQuantity || inspectionSamplingSize?.type === ESamplingSizeTypes.QUANTITY_SAMPLE_SIZE)
      ) {
        let { samplingSize } = workflow;

        if (isNil(samplingSize)) {
          const products: ISamplingSizeProduct[] = state.inspection.products.map((product: IAbstractMultiProduct): ISamplingSizeProduct => {
            return {
              // dirty workaround: tests based on sample size are wrongly based on produced quantity, and if the first step is hidden it will never have a value
              lotSize: preparationAction?.updatableByInspector ? undefined : product.productQuantity,
              productName: product.identifierValue,
              productId: product.productId,
              purchaseOrderId: product.purchaseOrderId,
              purchaseOrderProductId: product.purchaseOrderProductId,
              purchaseOrderReference: product.purchaseOrderReference,
            };
          });

          if (ESamplingSizeTypes.QUANTITY_SAMPLE_SIZE === inspectionSamplingSize?.type) {
            samplingSize = {
              type: ESamplingSizeTypes.QUANTITY_SAMPLE_SIZE,
              sampleSize: (inspectionSamplingSize as IFixedSamplingSize).sampleSize,
              products,
            } as IWorkflowFixedSamplingSize;
          } else {
            samplingSize = {
              inspectionLevel: (inspectionSamplingSize as ISamplingSize).inspectionLevel,
              codeLetter: (inspectionSamplingSize as ISamplingSize).codeLetter,
              products,
              criticalSampleSize: (inspectionSamplingSize as ISamplingSize).criticalSampleSize,
              majorSampleSize: (inspectionSamplingSize as ISamplingSize).majorSampleSize,
              minorSampleSize: (inspectionSamplingSize as ISamplingSize).minorSampleSize,
            } as IWorkflowSamplingSize;
          }
        }

        updatedWorkflow = WorkflowUtils.updateWorkflowSamplingSize(workflow, samplingSize);
      }

      return {
        ...state,
        isAborted: updatedWorkflow.isAborted || state.status === InspectionStatus.ABORTED,
        workflow: updatedWorkflow,
      };
    }),

    on(updateGlobalRemarksSuccess, (state, { remarks }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      return {
        ...state,
        workflow: {
          ...state.workflow,
          globalRemark: remarks,
        },
      };
    }),

    // Test checklist 🧪

    on(saveTestAnswerSuccess, (state, { id, value }): IInspectionState => {
      const workflow = cloneDeep(state.workflow);

      if (isNil(workflow)) {
        return state;
      }

      const element = WorkflowUtils.getTestById(workflow, id);

      if (element?.type === EQpQuestionType.TABLE) {
        const tableValue: QimaOptionalType<IQpTestsChecklistElementTableValue> = element.value as IQpTestsChecklistElementTableValue;

        tableValue?.rows?.forEach((row: IQpTestsChecklistElementTableRows): void => {
          const rowValue: QimaOptionalType<QpTestsChecklistElementTableColumnType> = row.columns.find(
            (col: QpTestsChecklistElementTableColumnType): boolean => col.id === id
          );

          if (value != undefined && value != null && !isEmpty(rowValue)) {
            rowValue.value = value.toString();
          }
        });

        element.value = tableValue;
      } else if (element) {
        element.value = value;
      }

      return {
        ...state,
        workflow,
      };
    }),

    on(saveTestTableRows, (state: IInspectionState, { id, rows }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const workflow: IWorkflow = cloneDeep(state.workflow);
      const element: IQpTestsChecklistElementTable = WorkflowUtils.getTestById(workflow, id) as IQpTestsChecklistElementTable;

      element.value.rows = rows;

      return {
        ...state,
        workflow,
      };
    }),

    on(deleteTestChecklistTableRowSuccess, (state: IInspectionState, { id, answerId }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const workflow: IWorkflow = cloneDeep(state.workflow);
      const element: IQpTestsChecklistElementTable = WorkflowUtils.getTestById(workflow, id) as IQpTestsChecklistElementTable;
      const rows: IQpTestsChecklistElementTableRows[] | undefined = element?.value?.rows?.filter(
        (row: IQpTestsChecklistElementTableRows): boolean => {
          return !row.columns.some((col: QpTestsChecklistElementTableColumnType): boolean => col.id === answerId);
        }
      );

      element.value.rows = rows;

      return {
        ...state,
        workflow,
      };
    }),

    on(commentTestSuccess, (state, { id, comment }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const workflow = cloneDeep(state.workflow);
      const element = WorkflowUtils.getTestById(workflow, id);

      if (!isNil(element)) {
        element.comment = comment;
      }

      return {
        ...state,
        workflow,
      };
    }),

    on(markAsNotApplicableSuccess, (state, { id, notApplicable }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const workflow = cloneDeep(state.workflow);
      const element = WorkflowUtils.getTestById(workflow, id);

      if (!isNil(element)) {
        element.notApplicable = notApplicable;
      }

      return {
        ...state,
        workflow,
      };
    }),

    // Checks 🧾

    on(saveCheckSuccess, (state, { actionId, check }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      let workflow = cloneDeep(state.workflow);

      workflow = WorkflowUtils.saveCheck(workflow, actionId, check);

      return {
        ...state,
        workflow,
      };
    }),

    // Defect checklist ❌

    on(createDefectSuccess, (state, { defect }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const updatedWorkflow = produce(state.workflow, (draft): void => {
        WorkflowUtils.addDefect(draft, defect);
      });

      return { ...state, workflow: updatedWorkflow };
    }),

    on(updateDefectSuccess, (state, { defectId, quantity, classification, purchaseOrderProductId }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const updatedWorkflow = produce(state.workflow, (draft): void => {
        WorkflowUtils.saveDefect(draft, defectId, { quantity, classification, purchaseOrderProductId });
      });

      return { ...state, workflow: updatedWorkflow };
    }),

    on(updateDefectCommentSuccess, (state, { defectId, comment, purchaseOrderProductId }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const updatedWorkflow = produce(state.workflow, (draft): void => {
        WorkflowUtils.saveDefect(draft, defectId, { comment, purchaseOrderProductId });
      });

      return { ...state, workflow: updatedWorkflow };
    }),

    on(completeStartingPicturesSuccess, (state: IInspectionState, { pathId }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(state.workflow.steps);

      clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
        step.actions.forEach((action: IWorkflowAction): void => {
          if (action.id === pathId) {
            action.validatedByInspector = true;
          }
        });
      });

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: clonedWorkflowSteps,
        },
      };
    }),

    on(completeDefectsChecklistSuccess, (state: IInspectionState, { stepId, actionId, purchaseOrderProductId }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(state.workflow.steps);

      clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
        if (Number(step.id) === stepId) {
          const actionPathId = `${stepId}-${actionId}`;

          step.actions.forEach((action: IWorkflowAction): void => {
            if (action.id === actionPathId && !isNil(action.defectsChecklist)) {
              if (
                !action.defectsChecklist.completions?.find(
                  (completion): boolean =>
                    !!completion.isCompleted && (!purchaseOrderProductId || completion.purchaseOrderProductId === purchaseOrderProductId)
                )
              ) {
                action.defectsChecklist.completions = [
                  ...(action.defectsChecklist.completions ?? []),
                  { isCompleted: true, purchaseOrderProductId },
                ];
              }
            }
          });
        }
      });

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: clonedWorkflowSteps,
        },
      };
    }),

    // Images 📷

    on(addInspectionPictureSuccess, (state: IInspectionState, { data, elementType, elementPathId, newImageId }): IInspectionState => {
      const updatedState = cloneDeep(state);

      if (isNil(updatedState.workflow)) {
        return updatedState;
      }

      if (elementType && elementPathId && data.imageId) {
        if (elementType === EQpImageElementType.ANSWERS) {
          updatedState.workflow = WorkflowUtils.addTestPicture(updatedState.workflow, elementPathId, data.imageId, data);
        } else if (elementType === EQpImageElementType.SCANS) {
          updatedState.workflow = WorkflowUtils.addTestScan(updatedState.workflow, elementPathId, data.imageId, data);
        } else if (elementType === EQpImageElementType.DEFECTS) {
          updatedState.workflow = WorkflowUtils.addDefectsPicture(updatedState.workflow, elementPathId, data.imageId, data);
        } else if (elementType === EQpImageElementType.SIMPLIFIED_MEASUREMENTS) {
          updatedState.workflow = WorkflowUtils.addSimplifiedMeasurementPicture(updatedState.workflow, elementPathId, data.imageId, data);
        } else if (elementType === EQpImageElementType.STARTING) {
          updatedState.workflow = WorkflowUtils.addStartingPicture(updatedState.workflow, elementPathId, data.imageId, data);
        }
      } else {
        updatedState.workflow = WorkflowUtils.addGlobalPicture(updatedState.workflow, newImageId, data);
      }

      return updatedState;
    }),

    on(
      deleteInspectionImageSuccess,
      (state: IInspectionState, { imageElementType, elementId, data }: IDeleteWorkflowImage): IInspectionState => {
        const updatedState = cloneDeep(state);

        if (isNil(updatedState.workflow)) {
          return updatedState;
        }

        if (imageElementType && data.imageId) {
          if (imageElementType === EQpImageElementType.ANSWERS) {
            updatedState.workflow = WorkflowUtils.deleteTestsPicture(updatedState.workflow, elementId, data.imageId);
          } else if (imageElementType === EQpImageElementType.DEFECTS) {
            updatedState.workflow = WorkflowUtils.deleteDefectsPicture(updatedState.workflow, elementId, data.imageId);
          } else if (imageElementType === EQpImageElementType.SCANS) {
            updatedState.workflow = WorkflowUtils.deleteTestScan(updatedState.workflow, elementId, data.imageId);
          } else if (imageElementType === EQpImageElementType.SIMPLIFIED_MEASUREMENTS) {
            updatedState.workflow = WorkflowUtils.deleteSimplifiedMeasurementPicture(updatedState.workflow, elementId, data.imageId);
          } else if (imageElementType === EQpImageElementType.STARTING) {
            updatedState.workflow = WorkflowUtils.deleteStartingPicture(updatedState.workflow, elementId, data.imageId);
          }
        } else if (isArray(updatedState.workflow.globalImages)) {
          const globalImageIndex: number = updatedState.workflow.globalImages.findIndex(
            (image: Readonly<IChecklistImageData>): boolean => image?.imageId === data?.imageId
          );

          if (globalImageIndex >= 0) {
            updatedState.workflow.globalImages.splice(globalImageIndex, 1);
          }
        }

        return updatedState;
      }
    ),

    on(updateInspectionPictureDataSuccess, (state: IInspectionState, { data, elementType, elementPathId, imageId }): IInspectionState => {
      const updatedState = cloneDeep(state);

      if (isNil(updatedState.workflow)) {
        return updatedState;
      }

      if (
        isNil(elementType) ||
        [
          EQpImageElementType.ANSWERS,
          EQpImageElementType.DEFECTS,
          EQpImageElementType.SIMPLIFIED_MEASUREMENTS,
          EQpImageElementType.STARTING,
        ].includes(elementType)
      ) {
        updatedState.workflow = WorkflowUtils.updatePicturesData(updatedState.workflow, elementType, elementPathId, [imageId], data);
      } else if (elementType === EQpImageElementType.SCANS && !isNil(data.caption)) {
        updatedState.workflow = WorkflowUtils.setTestScanCaption(updatedState.workflow, elementPathId, imageId, data.caption);
      }

      return updatedState;
    }),

    on(updateInspectionPicturesDataSuccess, (state: IInspectionState, { data, elementType, elementPathId, imageIds }): IInspectionState => {
      const updatedState = cloneDeep(state);

      if (isNil(updatedState.workflow)) {
        return updatedState;
      }

      updatedState.workflow = WorkflowUtils.updatePicturesData(updatedState.workflow, elementType, elementPathId, imageIds, data);

      return updatedState;
    }),

    on(orderInspectionPicturesSuccess, (state: IInspectionState, { orderedPicturesIds, entity, entityId }): IInspectionState => {
      const updatedState = cloneDeep(state);

      if (isNil(updatedState.workflow)) {
        return updatedState;
      }

      if (entity && entityId) {
        if (entity === EQpImageElementType.ANSWERS) {
          updatedState.workflow = WorkflowUtils.reorderTestsPictures(updatedState.workflow, entityId, orderedPicturesIds);
        } else if (entity === EQpImageElementType.DEFECTS) {
          updatedState.workflow = WorkflowUtils.reorderDefectsPictures(updatedState.workflow, entityId, orderedPicturesIds);
        } else if (entity === EQpImageElementType.SIMPLIFIED_MEASUREMENTS) {
          updatedState.workflow = WorkflowUtils.reorderSimplifiedMeasurementPictures(updatedState.workflow, entityId, orderedPicturesIds);
        }
      } else {
        updatedState.workflow = WorkflowUtils.reorderGlobalPictures(updatedState.workflow, orderedPicturesIds);
      }

      return updatedState;
    }),

    // Attachments
    on(addInspectionAttachmentSuccess, (state: IInspectionState, { data, entity, entityId }): IInspectionState => {
      const updatedState = cloneDeep(state);

      if (isNil(updatedState.workflow)) {
        return updatedState;
      }

      if (entity && entityId) {
        if (entity === EQpImageElementType.ANSWERS) {
          updatedState.workflow = WorkflowUtils.addTestsAttachment(updatedState.workflow, entityId, data);
        } else if (entity === EQpImageElementType.SIMPLIFIED_MEASUREMENTS) {
          updatedState.workflow = WorkflowUtils.addSimplifiedMeasurementAttachment(updatedState.workflow, data);
        }
      }

      return updatedState;
    }),
    on(deleteInspectionAttachmentSuccess, (state: IInspectionState, { attachmentId, entity, entityId }): IInspectionState => {
      const updatedState = cloneDeep(state);

      if (isNil(updatedState.workflow)) {
        return updatedState;
      }

      if (entity && entityId) {
        if (entity === EQpImageElementType.ANSWERS) {
          updatedState.workflow = WorkflowUtils.deleteTestAttachment(updatedState.workflow, entityId, attachmentId);
        } else if (entity === EQpImageElementType.SIMPLIFIED_MEASUREMENTS) {
          updatedState.workflow = WorkflowUtils.deleteSimplifiedMeasurementAttachment(updatedState.workflow, attachmentId);
        }
      }

      return updatedState;
    }),

    // Site Location 📍

    on(clearSiteLocation, (state: IInspectionState, { stepId, actionId }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const updateWorkflowSteps: IWorkflowStep[] = state.workflow.steps.map((step: IWorkflowStep): IWorkflowStep => {
        return {
          ...step,
          actions: step.actions.map((action: IWorkflowAction): IWorkflowAction => {
            return action.id !== `${stepId}-${actionId}` ? action : { ...action, entity: undefined };
          }),
        };
      });

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: updateWorkflowSteps,
        },
      };
    }),

    on(addSiteLocation, (state: IInspectionState, { entity, stepId, actionId }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const updateWorkflowSteps: IWorkflowStep[] = cloneDeep(state.workflow.steps).map((step: IWorkflowStep): IWorkflowStep => {
        return {
          ...step,
          actions: step.actions.map((action: IWorkflowAction): IWorkflowAction => {
            return action.id !== `${stepId}-${actionId}` ? action : { ...action, entity };
          }),
        };
      });

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: updateWorkflowSteps,
        },
      };
    }),

    // Recipients 📧

    on(saveRecipientsSuccess, (state: IInspectionState, { actionId, recipients }): IInspectionState => {
      const workflow = cloneDeep(state.workflow);

      if (isNil(workflow)) {
        return state;
      }

      const recipientAction = WorkflowUtils.getActionFromPath(workflow, actionId);

      recipientAction.ccRecipients = recipients;

      return {
        ...state,
        workflow,
      };
    }),

    // Custom fields ✨

    on(saveWorkflowActionCustomFieldsValueSuccess, (state: IInspectionState, { fieldId, answer }): IInspectionState => {
      const workflowToUpdate = cloneDeep(state.workflow);

      if (isNil(workflowToUpdate)) {
        return state;
      }

      const ispWorkflow = new IspWorkflow(workflowToUpdate);
      const customFieldsAction: QimaOptionalType<IspWorkflowStepActionCustomFields> = ispWorkflow.getCustomFieldsAction();
      const fieldToUpdate: QimaOptionalType<CustomFieldInspectionAnswerPairDTO> = customFieldsAction?.customFields.find(
        (f: CustomFieldInspectionAnswerPairDTO): boolean => f.customField.id === fieldId
      );

      if (fieldToUpdate) {
        fieldToUpdate.answer.value = answer.value;
      } else {
        throw new Error('Invalid custom field path');
      }

      return {
        ...state,
        workflow: {
          ...workflowToUpdate,
        },
      };
    }),
    // Simplified Measurements 📏

    on(saveSimplifiedMeasuresSuccess, (state: IInspectionState, { simplifiedMeasures }): IInspectionState => {
      if (isNil(state?.workflow)) {
        return state;
      }

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: state.workflow.steps.map(
            (step: IWorkflowStep): IWorkflowStep => ({
              ...step,
              actions: step.actions.map(
                (action: IWorkflowAction): IWorkflowAction =>
                  action.type === EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
                    ? {
                        ...action,
                        simplifiedMeasurement: {
                          ...action.simplifiedMeasurement,
                          measurement: simplifiedMeasures,
                        },
                      }
                    : action
              ),
            })
          ),
        },
      };
    }),

    on(saveSimplifiedMeasuresCommentSuccess, (state: IInspectionState, { comment }): IInspectionState => {
      if (isNil(state?.workflow)) {
        return state;
      }

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: state.workflow.steps.map(
            (step: IWorkflowStep): IWorkflowStep => ({
              ...step,
              actions: step.actions.map(
                (action: IWorkflowAction): IWorkflowAction =>
                  action.type === EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
                    ? ({
                        ...action,
                        simplifiedMeasurement: {
                          ...action.simplifiedMeasurement,
                          comment,
                        },
                      } as IWorkflowAction)
                    : action
              ),
            })
          ),
        },
      };
    }),

    on(updateSimplifiedMeasuresResult, (state: IInspectionState, { result }): IInspectionState => {
      if (isNil(state?.workflow)) {
        return state;
      }

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: state.workflow.steps.map(
            (step: IWorkflowStep): IWorkflowStep => ({
              ...step,
              actions: step.actions.map(
                (action: IWorkflowAction): IWorkflowAction =>
                  action.type === EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
                    ? ({
                        ...action,
                        simplifiedMeasurement: {
                          ...action.simplifiedMeasurement,
                        },
                        result,
                      } as IWorkflowAction)
                    : action
              ),
            })
          ),
        },
      };
    }),

    on(updateSimplifiedMeasurementSamplingSuccess, (state: IInspectionState, { sampling }): IInspectionState => {
      if (isNil(state?.workflow)) {
        return state;
      }

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: state.workflow.steps.map(
            (step: IWorkflowStep): IWorkflowStep => ({
              ...step,
              actions: step.actions.map(
                (action: IWorkflowAction): IWorkflowAction =>
                  action.type === EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
                    ? ({
                        ...action,
                        simplifiedMeasurement: {
                          ...action.simplifiedMeasurement,
                          sampling,
                        },
                      } as IWorkflowAction)
                    : action
              ),
            })
          ),
        },
      };
    }),

    on(updateSimplifiedMeasurementSamplingModificationReasonSuccess, (state: IInspectionState, { reason }): IInspectionState => {
      if (isNil(state?.workflow)) {
        return state;
      }

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: state.workflow.steps.map(
            (step: IWorkflowStep): IWorkflowStep => ({
              ...step,
              actions: step.actions.map(
                (action: IWorkflowAction): IWorkflowAction =>
                  action.type === EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
                    ? ({
                        ...action,
                        simplifiedMeasurement: {
                          ...action.simplifiedMeasurement,
                          samplingModificationReason: reason,
                        },
                      } as IWorkflowAction)
                    : action
              ),
            })
          ),
        },
      };
    }),

    on(saveSimplifiedMeasuresMarkedAsNotApplicableSuccess, (state: IInspectionState, { notApplicable }): IInspectionState => {
      if (isNil(state?.workflow)) {
        return state;
      }

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: state.workflow.steps.map(
            (step: IWorkflowStep): IWorkflowStep => ({
              ...step,
              actions: step.actions.map(
                (action: IWorkflowAction): IWorkflowAction =>
                  action.type === EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
                    ? ({
                        ...action,
                        simplifiedMeasurement: {
                          ...action.simplifiedMeasurement,
                          notApplicable,
                        },
                      } as IWorkflowAction)
                    : action
              ),
            })
          ),
        },
      };
    }),

    // Measurements 📏

    on(saveMeasurementSuccess, (state: IInspectionState, { measurementId, sectionName, measure }): IInspectionState => {
      const path: number[] = WorkflowUtils.getPath(measurementId);
      const workflow = cloneDeep(state.workflow);

      if (isNil(workflow)) {
        return state;
      }

      const productIndex = path[2];
      const variances = WorkflowUtils.getCategoryByTestId(
        workflow,
        measurementId,
        EWorkflowActionType.MEASURES_CHECKLIST,
        productIndex
      ) as IVariance[];

      variances[path[3]].measuresEntries
        .reduce((acc, entry): IMeasure[] => [...acc, ...entry.measureAnswers], [] as IMeasure[])
        .filter(
          (measureEntry: IMeasure): boolean =>
            measurementId === measureEntry.id && (isNil(sectionName) || measureEntry.section === sectionName)
        )
        .forEach((measureEntry: IMeasure): void => {
          measureEntry.measure = measure;
        });

      return {
        ...state,
        workflow,
      };
    }),

    on(addCustomMeasurementProductSampleSuccess, (state: IInspectionState, { path, sectionName }): IInspectionState => {
      const workflow = cloneDeep(state.workflow);
      const inspection = cloneDeep(state.inspection);
      const pathIds: number[] = WorkflowUtils.getPath(path);

      if (isNil(workflow) || isNil(inspection)) {
        return state;
      }

      if (pathIds.length < 4) {
        console.error('Invalid measurement path:', pathIds);

        return state;
      }

      if (qpIsFiniteNumber(inspection.measurementsSamplingSize.sampleSize)) {
        inspection.measurementsSamplingSize.sampleSize++;
      }

      return {
        ...state,
        workflow: WorkflowUtils.addMeasureEntry(workflow, pathIds, sectionName),
        inspection,
      };
    }),

    // Product picture 📸

    on(saveProductPictureSuccess, (state: IInspectionState, { actionId, action }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const workflow = cloneDeep(state.workflow);
      const updatedAction = WorkflowUtils.getActionFromPath(workflow, actionId);

      updatedAction.documentId = action.documentId;
      updatedAction.pictureSource = action.pictureSource;
      const updateWorkflowSteps: IWorkflowStep[] = workflow.steps.map((step: IWorkflowStep): IWorkflowStep => {
        return {
          ...step,
          actions: step.actions.map((workflowAction: IWorkflowAction): IWorkflowAction => {
            return workflowAction.id === actionId ? updatedAction : workflowAction;
          }),
        };
      });

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: updateWorkflowSteps,
        },
      };
    }),

    // Findings summary review 📝

    on(confirmFindingsSummaryReviewSuccess, (state): IInspectionState => {
      if (state.workflow) {
        const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(state.workflow.steps);

        clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
          step.actions.forEach((action: IWorkflowAction): void => {
            if (action.type === EWorkflowActionType.FINDINGS_SUMMARY_REVIEW) {
              action.findingsSummaryReview = {
                ...action.findingsSummaryReview,
                isConfirmed: true,
              };
            }
          });
        });

        return {
          ...state,
          workflow: {
            ...state.workflow,
            steps: clonedWorkflowSteps,
          },
        };

        // if no workflow inside the state, just return the existing state
      }

      return state;
    }),

    on(updateIsInspectionMarkedAsPendingSuccess, (state, { isInspectionMarkedAsPending }): IInspectionState => {
      if (state.workflow) {
        return {
          ...state,
          workflow: WorkflowUtils.updateFindingSummaryReviewPendingState(state.workflow, isInspectionMarkedAsPending),
        };
      }

      return state;
    }),

    // Inspection date 📆

    on(updateInspectionDate, (state: IInspectionState, { inspectionDate }): IInspectionState => {
      if (isNil(state.inspection)) {
        return state;
      }

      return {
        ...state,
        inspection: {
          ...state.inspection,
          inspectionDate,
        },
      };
    }),

    // Reference sample

    on(completeReferenceSampleSuccess, (state: IInspectionState): IInspectionState => {
      if (state.workflow) {
        const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(state.workflow.steps);

        clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
          step.actions.forEach((action: IWorkflowAction): void => {
            if (action.type === EWorkflowActionType.REFERENCE_SAMPLE && !isNil(action.referenceSample)) {
              action.referenceSample = {
                ...action.referenceSample,
                isCompleted: true,
              };
            }
          });
        });

        return {
          ...state,
          workflow: {
            ...state.workflow,
            steps: clonedWorkflowSteps,
          },
        };

        // if no workflow inside the state, just return the existing state
      }

      return state;
    }),

    // Sample collection

    on(completeSampleCollectionSuccess, (state: IInspectionState): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(state.workflow.steps);

      clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
        step.actions.forEach((action: IWorkflowAction): void => {
          if (action.type === EWorkflowActionType.SAMPLE_COLLECTION && !isNil(action.sampleCollection)) {
            action.sampleCollection = {
              ...action.sampleCollection,
              isCompleted: true,
            };
          }
        });
      });

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: clonedWorkflowSteps,
        },
      };
    }),

    // Picture tags

    on(createPictureTagSuccess, (state: IInspectionState, { pictureTag }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      return {
        ...state,
        workflow: {
          ...state.workflow,
          pictureTags: [...(state.workflow.pictureTags || []), pictureTag],
        },
      };
    }),

    on(updatePictureTagSuccess, (state: IInspectionState, { pictureTag }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      return {
        ...state,
        workflow: {
          ...state.workflow,
          pictureTags: (state.workflow.pictureTags || []).map(
            (tag: PictureTagDTO): PictureTagDTO => (tag.pictureTagUUID === pictureTag.pictureTagUUID ? pictureTag : tag)
          ),
        },
      };
    }),

    on(deletePictureTagSuccess, (state: IInspectionState, { pictureTag }): IInspectionState => {
      if (isNil(state.workflow)) {
        return state;
      }

      const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(state.workflow.steps);

      clonedWorkflowSteps?.forEach((step: IWorkflowStep): void => {
        step.actions.forEach((action: IWorkflowAction): void => {
          if (action.type === EWorkflowActionType.TESTS_CHECKLIST && !isNil(action.testsChecklist)) {
            action.testsChecklist.content?.elements?.forEach((elementArray): void => {
              elementArray.elements?.forEach((element): void => {
                element.images?.forEach((image): void => {
                  if (image.pictureTags) {
                    image.pictureTags = image.pictureTags.filter((tag): boolean => tag !== pictureTag.pictureTagUUID);
                  }
                });
              });
            });
          }
        });
      });

      return {
        ...state,
        workflow: {
          ...state.workflow,
          steps: clonedWorkflowSteps,
          pictureTags: (state.workflow?.pictureTags ?? []).filter(
            (tag: PictureTagDTO): boolean => tag.pictureTagUUID !== pictureTag.pictureTagUUID
          ),
        },
      };
    })
  );
}

/**
 * @param {IInspectionState | undefined} state - The state
 * @param {Action} action - The action
 * @returns {IInspectionState} - The state after the reduce
 */
export function ispInspectionIdReducer(state: IInspectionState | undefined, action: Action): IInspectionState {
  return actionReducer()(state, action);
}
